<template>
  <div class="d-flex flex-column">
    <p class="ma-0 secondary-text--text">{{ label }}</p>
    <p class="ma-0 mt-1 primary-text--text">
      <strong>
        <slot></slot>
      </strong>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>
